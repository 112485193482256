import {
  Box,
  Grid,
  TextField,
  Checkbox,
  Typography,
  Card,
  Button,
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import Buttons from "../../../components/Controls/buttons/Button";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import * as acts from "../../../redux/administrator/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FolderIcon from "@mui/icons-material/Folder";
import AddIcon from "@mui/icons-material/Add";
import ProgressBar from "../../../components/ProgressBar";
import "./index.scss";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import AdministratorHeader from "../../../components/header/AdministratorHeader";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function ListDirectory() {
  const Dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state?.admin?.batchTypes);
  const productType = useSelector((state) => state?.admin?.productType);
  let folderData = useSelector((state) => state?.admin?.subFolder);
  const [value, setValue] = React.useState(null);
  const [folderName, setFolderName] = React.useState("");
  const [folderNameFrom, setFolderNameFormList] = React.useState("");
  const [disableAdd, setDisableAdd] = React.useState();
  const apiStatus = useSelector((state) => state?.admin?.apiStatus);
  const [message, setMessage] = useState("");
  const [err, setErr] = useState(false);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [expandedData, setExpandedData] = useState([]);
  const [id, setId] = useState("");
  useEffect(() => {
    if (!expandedData[id]) {

      setExpandedData({
        ...expandedData,
        [id]: folderData?.data,
      });
      if (expandedNodes.includes(id)) {
        setExpandedNodes(expandedNodes.filter((nodeId) => nodeId !== id));
      } else {
        setExpandedNodes([...expandedNodes, id]);
      }
    }
  }, [folderData]);

  useEffect(() => {
    if (data?.upload_in_progress == true) {
      history.push("/review-batch-records-details");
    }
  }, [data]);

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.apiError === true && apiStatus?.next === false) {
        setMessage(apiStatus?.message);
        setErr(true);
      }
    }
  }, [apiStatus]);

  const handleExpand = async (id) => {
    setId(id);
    await Dispatch(acts.getProductTypeSubFolder(id));

  }

  const isNodeExpanded = (id) => expandedNodes.includes(id);

  const memoizedRenderTree = useMemo(() => {
    const cache = {};

    const renderTreeNode = (items) => {
      if (!Array.isArray(items) || items.length === 0) {
        return null;
      }

      return items.map((item) => {
        if (!cache[item.id]) {
          cache[item.id] = (
            <TreeItem
              key={item.id}
              nodeId={item.id}
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Radio id={item.id}
                    onClick={() => {
                      handleClick(item);
                    }} style={{ marginRight: "10px" }} />

                  <FolderIcon className="folder-icon" />
                  {/* first level folder............ */}
                  <p>{item.name}</p>


                  <div style={{ marginLeft: "auto" }}>
                    <Button onClick={() => handleExpand(item.id)}>
                      {isNodeExpanded(item.id) ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </Button>
                  </div>
                </div>
              }
            >
              {renderTreeNode(expandedData[item.id])}
            </TreeItem>
          );
        }
        return cache[item.id];
      });
    };
    return renderTreeNode(data?.data);
  }, [data, expandedData, isNodeExpanded, handleExpand]);



  useEffect(() => {
    Dispatch(acts.getBatchRecordType());
    Dispatch(acts.getDrugTypeList());
  }, [Dispatch]);

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.message != null && apiStatus?.next === false) {
        if (apiStatus?.message === "Invalid Token") {
          setMessage(apiStatus?.message);
          setErr(true);
        }
      }
    }
  }, [apiStatus]);

  const handleClick = (data) => {
    setValue(data?.id);
  };
  const handleAdd = (data) => {
    if (folderName == "") {
      return;
    } else {
      setDisableAdd(true);
    }
  };
  const handleContine = () => {
    if (disableAdd == false) {
      return;
    }
    if (!value || !folderName) {
      return;
    } else {
      let sendData = {
        value,
        folderName,
      };
      history.push("/upload-batch", sendData);
    }
  };

  const handleChange = (event) => {
    setFolderNameFormList(event.target.value);
    setFolderName(event.target.value);
    setDisableAdd(true);
  };
  return (
    <div>
      <Header />
      <Box className="list-directory">
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid className="header-select" item md={9} sm={8}>
            <AdministratorHeader
              position="right"
              text="Batch Records"
              isShow={true}
              to="/review-batch-records-details"
            />
            <Box className="Add-batch-records">
              <TextField
                placeholder="Enter Drug Type"  // const handleClick = (data) => {
                //   setValue(data?.id);
                // };
                disabled={disableAdd}
                onChange={(event) => setFolderName(event.target.value)}
                id="outlined-basic"
                variant="outlined"
              />
              <Buttons
                text="Add"
                onClick={handleAdd}
                startIcon={<AddIcon />}
                size="large"
                className="add-btn"
              />

              <Box>
                <FormControl className="ProductTypeList-wrap">
                  <InputLabel
                    className="ProductTypeList"
                    id="demo-simple-select-autowidth-label"
                  >
                    DrugTypeList
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={folderNameFrom}
                    onChange={handleChange}
                    autoWidth
                  >
                    {productType?.map((list) => (
                      <MenuItem
                        key={list}
                        value={list.name}
                        className="batch-product-type"
                      >
                        {list.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Buttons
                text="Continue"
                disabled={!value || !disableAdd}
                onClick={handleContine}
                className="btn-continue"
              />
            </Box>
            <Grid
              container
              // spacing={{ xs: 2, md: 3 }}
              // columns={{ xs: 4, sm: 8, md: 12 }}
              className="list_directory-container"
            >
              {/* <Box > */}
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                multiSelect
              >
                {memoizedRenderTree}
              </TreeView>
              {folderData?.loader === true ? <ProgressBar /> : ""}
              {/* </Box> */}
            </Grid>
            {err && (
              <CustomizedSnackbars
                severity="error"
                state={err}
                text={message}
                handleClose={() => setErr(false)}
              />
            )}
            {/* <Box
             className="list-directory-FBuuton"
            >
              <Buttons
                text="Continue"
                disabled={!value || !disableAdd}
                onClick={handleContine}
                size="large"
              />
            </Box> */}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
