import React, { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Button,
  FormControl,
  Box,
  Paper,
  OutlinedInput,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { cloneProject, getPreProProjects } from '../../../redux/pre_processor/actions';
import "./cloneModal.scss";


export default function CloneModal({ open, onCloseClone }) {

  const [projectName, setProjectName] = useState(localStorage.projectName + "_clone");
  const dispatch = useDispatch();
  const apiStatus = useSelector((state) => state?.prePro?.apiStatus);

  useEffect(() => {
    //dispatch(validateUserClear());
    if (apiStatus && apiStatus != undefined) {
      if (apiStatus.loading === true) {

      } if (apiStatus.loading === false) {

        if (apiStatus.next === true || apiStatus.message != null) {
          setTimeout(() => {
            onCloseClone(false)
            dispatch(getPreProProjects({
              type: "",
              formattedStartDate: "",
              formattedEndDate: "",
              status: "",
            }))
          }, 2000);
        }
      }
    }
  }, [apiStatus]);
  const handleProjectName = (name) => {

    setProjectName(name);
  }
  const submit = () => {
    dispatch(cloneProject(projectName));
  }

  return (
    <div>
      <Modal
        open={open}

      >
        <Box component={Paper} className="modal-box">
          <CloseIcon
            className="cloneModal-close"
            onClick={() => onCloseClone()}
          />
          <Typography id="modal-modal-title"
            variant="h8" component="h4">
            Clone Project
          </Typography>

          <FormControl fullWidth>
            <div className="clone" >
              {/* // <label>{messages.USER_NAME_AUTH}</label> */}
              <OutlinedInput
                size="small"
                fullWidth
                placeholder="Project Name"
                defaultValue={localStorage.projectName + "_clone"}
                onChange={(e) => handleProjectName(e.target.value)}
                className="projectName-outline"
                value={projectName}
              />
              <Button className="button-clone" onClick={submit}>Clone</Button>
            </div>

          </FormControl>
        </Box>
      </Modal>
    </div>
  );
}